import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue')
    }, {
        path: '/life',
        name: 'Life',
        component: () =>
            import ('../views/Life.vue')
    }, {
        path: '/about_us',
        name: 'About_us',
        component: () =>
            import ('../views/About_us.vue')
    }, {
        path: '/contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue')
    }, {
        path: '/uploadnews',
        name: 'Uploadnews',
        component: () =>
            import ('../views/Uploadnews.vue')
    }, {
        path: '/deatil',
        name: 'deatil',
        component: () =>
            import ('../views/deatil.vue')
    }, {
        path: '/search',
        name: 'search',
        component: () =>
            import ('../views/search.vue')
    }, {
        path: '/Agreement',
        name: 'Agreement',
        component: () =>
            import ('../views/Agreement.vue')
    },
    {
        path: '/yinsi',
        name: 'yinsi',
        component: () =>
            import ('../views/yinsi.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path != '/') {
        document.body.scrollTop = 0
            // firefox
        document.documentElement.scrollTop = 0
    } else {
        document.body.scrollTop = 0
            // firefox
        document.documentElement.scrollTop = 0
    }
    next()
})
export default router