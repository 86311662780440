import http from '../http.js';
// 提交报名信息
function smssend(params) {
    return http.post('/api/user/mobilelogin', params);
}

function send(params) {
    return http.post('/api/sms/send', params);
}
export default {
    smssend,
    send
}