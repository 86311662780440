<template>
	<div>
		<!-- <div class="box fcc">
			<div class="con fc">
				<img class="logo" src="../assets/logo2.png" alt="">
				<div class="nav">
					<div class="item " @click="changenav('/')">首页</div>
					<a :href="a"><div class="item" >国家发改委</div></a>
					<a :href="a2"><div class="item">国家能源局</div></a>
					<div class="item" @click="changenav('/about_us')">关于我们</div>
					
					<div class="item" @click="changenav('/uploadnews')">投稿</div>
				</div>
			</div>
		</div> -->
		<div class="beian fcc" style="margin-top: 100px;">{{beian}}</div>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		props: {},
		data() {
			return {
				info: {},
				token:'',
				a:'',
				a2:'',
				beian:""

			}
		},
		created() {
			this.tab()
		
		
		},
		methods: {
			tab(){
				this.$https
				.get(`/index/beian`)
				.then((res) => {
				console.log(res,21212121);
				this.beian=res.data
					
					
				});
				 
				
			},



			changenav(path) {
					
				if(path=='/uploadnews'&&!this.token!=''){
				
					alert('请登录')
					return
				}
				sessionStorage.setItem('pagepath', path)
				this.$router.push({
					path: path
				});
			},
			gopage(url) {
				window.open(url, "_blank");
			}
		}
	}
</script>

<style scoped>
	.box {
		width: 100%;
		height: 220px;
		background: #333333;
	}

	.box .con {
		width: 1200px;
	}

	.box .logo {
		width: 164px;
		height: 50px;
		margin-right: 270px;
	}

	.box .nav {
		width: 250px;
		font-size: 16px;
		
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	 .item {
		width: 90px;
		margin: 12px 0;
		color: #ffffff;
	}
 .item:hover{
	 color: #2B6CDB;
 }
	.beian{
	
		width: 100%;
		height: 60px;
		background: #89d700;
		font-size: 12px;
		color: #ffffff;
	
	}
</style>