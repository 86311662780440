<template>
  <div >
    <el-row style="margin: 0px;border-bottom: 0px ; position: fixed;top: 0;width: 100%;">
      <el-col :span="24" style="background-color: #0095e8;	">
        <div style=" width: 1200px; margin: 0 auto; height: 113px;display: flex;align-items: center;justify-content: space-between;padding:0 0 0 24px;">
           <!-- class="grid-content bg-purple-dark" -->
          <div style="display: flex;align-items: center;"  >
            <img class="ssa" @click="back()"  :src="logsur" alt="" />
            <!-- <div>重庆图书馆</div> -->
          </div>
        </div>
      </el-col>
    </el-row>

    
      
  </div>
</template>

<script>
export default {
  data() {
    return {
      logsur:''
    };
  },
  created() {
			 	this.logo()
		},
  methods: {
	  back(){
		  console.log(1111111111111);
				this.$router.push({
					path: '/',
						})
				},
	  logo(){
		  	this.$https
				.get(`/index/logo`,)
				.then((res) => {
				
				this.logsur=res.data
				});
	  },
    commands(command){
       console.log(111111);
      console.log(command);
     
    },
    onfo() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
    toggle() {
      this.fag = !this.fag;
    },
    save(path) {
      window.localStorage.setItem("path", path);
      const path1 = window.localStorage.getItem("path");
      this.path = `${path1}`;
      console.log(this.path);
    },
  },
  mounted() {
    this.axios.get("menus").then((res) => {
      // console.log(res.data.data);
      this.cai = res.data.data;
      console.log( this.cai);
    });
  },
};
</script>

<style>
.ssa:hover{ cursor:pointer;}
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
.tac{
  background-color: #333 !important;
}
.but {
  position: absolute;
  right: 0;
  top: 0;

  margin: 25px;
}
.body {
  display: flex;

}
.el-aside {
  /* background-color: #d3dce6; */
  color: #fff;
  text-align: center;
  line-height: 200px;
  width:200px
}

.el-main {
  background-color: #fff;
  color: #fff;
}
.el-container{
  height: 100vh;
  background-color: #fff;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.loin {
  background-color: #fff;
  font-size: 10px;
  line-height: 24px;
  color: #333;
  text-align: center;
  cursor: pointer;
}
</style>