<template>
  <div style="display: flex;justify-content: center; width: 100vw;">
   
 <!-- <div class="main-box">
    <div class="g-of-h">
      <div class="g-f-l box-l animated"></div>
      <div class="g-f-l box-r animated"></div>
    </div>
    <div class="box-b animated"></div>
  </div> -->
    
  <div style="	width: 1200px; margin-top: 200px;display: flex;flex-wrap: wrap;">
      <div  class="box-b animated" style="box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);border-bottom: 5px solid #f59e01 ;width: 272px;height: 280px;background:#fff;border-radius: 10px 10px 0 0; position: relative; margin-left: 20px;" v-for="(item,index) in list" :key="index">
            <div class="top">
              <img :src="item.image" alt="" style="width: 45px;height: 40px;">
            </div>
            <div style=" display: flex;justify-content: center; align-items: center;flex-direction: column;	">
              <div class='s1'>
                {{item.name}}
              </div>
            <div class='s2'>
              {{item.introduce}}
            </div>
            <div class='s3' @click="deatil(item)">
              更多
            </div>
          <div>
            
          </div>
            
          </div>
      </div>
  </div>
    
    
      
      
   
   
  </div>
</template>

<script>
export default {
    
  data() {
    var checkMobile = (rule, value, callback) => {
      const regMobile =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      callback(new Error("请输入合法手机号"));
    
    }
    return {
      list:[],
    
    };
  },
  	created() {
			 	this.chilelist()
		},
  methods: {
	  chilelist(){
		 
				this.$https
				.get(`/index`,)
				.then((res) => {
				console.log(res);
				this.list=res.data
				

			
				});
  			
	  },
    deatil(id){
				this.$router.push({
					path: '/life',
				 query: {detailsid: id.id , name:id.name  }
				
				})
			},
  
   
    
  
  },

  mounted() {
    this.getmin();
  },
};
</script>

<style>

.top{
  color: aqua;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff ;
    
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

 position: absolute;
 top: -50px;
 left:100px
}

.s1{
     color: #333333;
    font-size: 22px;
    font-weight: 700;
    margin-top: 50px;

}
.s1:hover{
   color: #89d700;
   cursor:pointer;
}
.s2{
  margin-top: 20px;
  color: #777777;
    font-size: 14px;
    width: 168px;
	height: 60px;
	text-align: center;
	display: -webkit-box;
	word-break: break-all;
	/*超出文字用省略号代替*/
	text-overflow: ellipsis;
	/*竖直方向的超出和隐藏*/
	-webkit-box-orient: vertical;
	/*设定行数为2*/
	-webkit-line-clamp: 3;
	/*多出部分隐藏*/
	overflow: hidden;

}
.s3{
  border: 1px solid #dddddd;
    border-radius: 5px;
    color: #777777;
   margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    height: 44px;
 
    padding: 0 36px;
     display: flex;
  align-items: center;
  justify-content: center;
 
}
.s3:hover{
  background: #89d700;
  color: #fff;
  cursor:pointer;
}



.g-of-h{
  overflow: hidden;
}
.g-f-l{
  float: left;
}
.g-f-r{
  float: right;
}
.main-box{
  width: 1200px;
  margin: 0 auto;
}
.box-l{
  width: 200px;
  height: 200px;
  background-color: #8a6de9;
  animation-name: fadeLeftIn;
  -webkit-animation-name: fadeLeftIn;
}
.box-r{
  margin-left: 20px;
  width: 500px;
  height: 200px;
  background-color: #FDB9B9;
  animation-name: fadeRightIn;
  -webkit-animation-name: fadeRightIn;
}
.box-b{

 
  animation-name: fadeBottomIn;
  -webkit-animation-name: fadeBottomIn;
 

}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes fadeLeftIn {
  0%{
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }
  100%{
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeRightIn {
  0%{
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
  100%{
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeBottomIn {
  0%{
    opacity: 0;
    transform: translate3d(0,100%,0);
    -webkit-transform: translate3d(0,100%,0);
  }
  100%{
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}
</style>