<template>
	<div>
		<div class="box">
                              <div style=" margin-top: 50px;"  >
                                    <div  v-for="(items,indexs) in comments" :key="indexs"  class="pin-fl" >
                                    <div>
                                      <el-avatar class="header-img" :size="40" :src="avatar"></el-avatar>
                                    </div>
                                    <div>
                                        <div class="pin-fl">
                                          <span class="pin-1">章瑞政</span>
                                          <span class="pin-2">2023.02.03  14:15:15</span>
                                      </div>
                                      <div class="pin-cont" >
                                        如果你问我：这么多颜色怎么怎么在短时间内调出来？【答：心中有色，自然神，看的多就会了。 如果你质疑：这配色也就普普通通。 【那么，先忽略上一问题，我今天看半年前的作品也觉得很丑，人嘛，总是在进步的~ 
                                      </div>
                                      <div class="pin-cont">
                                          <el-button type="info" plain size="small">回复</el-button>  
                                                   
                                      </div>
                                    </div>
                                </div>
                                </div>
                                   <!-- <keep-alive>
                                    <deatil  :comments="items.lists" ></deatil>
                                  </keep-alive> -->
                                 <!-- <div @click="pintag=true" style="margin-top: 20px;" v-if="!pintag">展开评论<i class="el-icon-arrow-down"></i></div>
                                <div  @click="pintag=false" style="margin-top: 20px;" v-if="pintag">收起评论<i class="el-icon-arrow-up"></i></div> -->
		
		</div>
	</div>
</template>

<script>
	export default {
       
        components: [],
        props:['comments'],
		data() {
			return {
			  avatar: "https://s1.ax1x.com/2022/06/10/Xc9lUf.png", //默认用户头像
     
        pintag:false,
        list:[
          {
            name:1,
            lists:[
               {
                mame:'1',
                lists:[
                  {
                      mame:'1',
                  }
                ]
               },
               {mame:'1'}
            ]

          }
        ],





        chooseList1:[],
				id:'',
				name:'',
				index:"",
				obj:{}
			}
		},
		created() {
			
		},
		methods: {
			  
 








              
		}
	}
</script>

<style scoped>
  .pin-title{
    font-size: 36px;
    font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
    font-weight: bold;
    color: #333333;
  }
  .pin-fl{
    display: flex;
   
  }
  .pin-cont{
    margin-top: 15px;
     margin-left: 16px;
    font-size: 24px;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    font-weight: 400;
    color: #131A27;
  }
  .pin-1{
    font-size: 28px;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    font-weight: 400;
    color: #333333;
    margin-right: 14px;
    margin-left: 16px;
  }
  .pin-2{
    font-size: 26px;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    font-weight: 400;
    color: #A6AFBC;
   
  }





.text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 480px;
  }







.pin{
	display: flex;
	align-items: center;
}
.dian{
	font-size: 18px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
  
}
.fr-b{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
	.box {
		width: 1200px;
		margin: 56px auto;
	
       
	}
    .bot{
        display: flex;
        margin-top: 52px;
        
    }
.buts{
    width: 240px;
    height: 56px;
    background: #E6E6E6;
    border-radius: 50px 50px 50px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    font-size: 18px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #999999;
 
}
.trages{
    background: #0148C4;
    color: #fff;
}
	.title {
       
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 41px;
		font-size: 36px;
		color: #222222;
		
	}
    .titles{
        margin-top: 64px;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #999999;
    }

	.p {
		font-size: 16px;
		color: #333333;
		line-height: 55px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.recommend {
		margin-bottom: 30px;
	}

	.recommend .img {
		width: 820px;
		height: 400px;
		overflow: hidden;
	}

	.recommend .img img {
		width: 820px;
	}

	.recommend .info {
		width: 346px;
		height: 400px;
	}

	.recommend .info .item {
		height: 68px;
		border-bottom: #AAAAAA dashed 1px;
		margin: 12px 0;
		font-size: 18px;
		color: #666666;
		font-weight: bold;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		line-height: 68px;
		cursor: pointer;
	}

	.recommend .info .item:hover {
		color: #0148C4;
	}

	.list {
		width: 1200px;
		height: 144px;
		margin-bottom: 30px;
		box-sizing: border-box;
		padding: 0 17px;
		border-bottom: #AAAAAA dashed 1px;
		cursor: pointer;
	}

	.list:hover {
		background: #F5F5F5;
		border-bottom: #ffffff solid 1px;
		transition: all 0.5s;
		border-radius: 10px;
	}
	
	.list:hover .info .list_title{
		color: #0148C4;
	}

	.list .time {
		width: 100px;
		text-align: center;
		color: #222222;
	}

	.list .time .t1 {
		font-size: 60px;
	}

	.list .time .t2 {
		font-size: 14px;
	}

	.list .info {
		width: 1054px;
	}

	.list .info .list_title {
		font-size: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		margin-bottom: 5px;
	}

	.list .info .list_p {
		font-size: 16px;
		color: #666666;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
</style>